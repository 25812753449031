import React from 'react';
import { useIntl } from 'react-intl';
import { PublicFormWrapper } from '../_components/PublicFormWrapper';
import { ActivateAccountForm } from './components/ActivateAccountForm';
import messages from './components/ActivateAccountForm/messages';

export function ActivateAccountPage() {
  const { formatMessage } = useIntl();
  return (
    <PublicFormWrapper title={formatMessage(messages.ImsActivateAccountTitle)}>
      <ActivateAccountForm onSuccess={() => {}} />
    </PublicFormWrapper>
  );
}
