import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonSave: {
    defaultMessage: 'Save changes',
    description: 'Save button',
    id: 'button.save',
  },
  mobileVideoAppConnectionAfterMigrationMessage: {
    defaultMessage: 'Use the QR code to log in to the My2N mobile application.',
    description: 'Mobile video app connection - message',
    id: 'mobileVideoAppConnection.afterMigration.message',
  },
  mobileVideoAppConnectionAfterMigrationTitle: {
    defaultMessage: 'My2N mobile app login',
    description: 'Mobile video app connection - title',
    id: 'mobileVideoAppConnection.afterMigration.title',
  },
  mobileVideoAppConnectionBeforeMigrationMessage: {
    defaultMessage: 'Use the QR code to link this device with the My2N app.',
    description: 'Mobile video app connection - message',
    id: 'mobileVideoAppConnection.beforeMigration.message',
  },
  mobileVideoAppConnectionBeforeMigrationTitle: {
    defaultMessage: 'Mobile App connection',
    description: 'Mobile video app connection - title',
    id: 'mobileVideoAppConnection.beforeMigration.title',
  },
  mobileVideoProxyInfoDeviceId: {
    defaultMessage: 'Device ID',
    description: 'Proxy info - device id',
    id: 'mobileVideoProxyInfo.deviceId',
  },
  mobileVideoProxyInfoDevicePassword: {
    defaultMessage: 'Device Password',
    description: 'Proxy info - device password',
    id: 'mobileVideoProxyInfo.devicePassword',
  },
  mobileVideoProxyInfoProxyAddress: {
    defaultMessage: 'SIP Proxy Address',
    description: 'Proxy info - SIP Proxy Address',
    id: 'mobileVideoProxyInfo.proxyAddress',
  },
  mobileVideoProxyInfoProxyPort: {
    defaultMessage: 'SIP Proxy Port',
    description: 'Proxy info - SIP Proxy Port',
    id: 'mobileVideoProxyInfo.proxyPort',
  },
  mobileVideoProxyInfoSecuredCallsSipsActive: {
    defaultMessage: 'SIPS active',
    description: 'Proxy info - sips active',
    id: 'mobileVideoProxyInfo.securedCalls.sipsActive',
  },
  mobileVideoProxyInfoSecuredCallsSrtpActive: {
    defaultMessage: 'SRTP active',
    description: 'Proxy info - srtp active',
    id: 'mobileVideoProxyInfo.securedCalls.srtp.active',
  },
  mobileVideoProxyInfoSecuredCallsSrtpInactive: {
    defaultMessage: 'SRTP inactive',
    description: 'Proxy info - srtp inactive',
    id: 'mobileVideoProxyInfo.securedCalls.srtp.inactive',
  },
  mobileVideoProxyInfoSecuredCallsTitle: {
    defaultMessage: 'Secured calls',
    description: 'Proxy info - secured calls title',
    id: 'mobileVideoProxyInfo.securedCalls.title',
  },
  mobileVideoProxyInfoSecuredCallsUnavailableOldAppVersionText: {
    defaultMessage: 'SIPS and SRTP unavailable',
    description: 'Proxy info - sips and srtp unavailable',
    id: 'mobileVideoProxyInfo.securedCalls.unavailable.oldAppVersion.text',
  },
  mobileVideoProxyInfoSecuredCallsUnavailableOldAppVersionTooltip: {
    defaultMessage: 'Update mobile app to make {br} SIPS and SRTP available',
    description: 'Proxy info - sips and srtp old app version tooltip',
    id: 'mobileVideoProxyInfo.securedCalls.unavailable.oldAppVersion.tooltip',
  },
  mobileVideoProxyInfoSecuredCallsUnavailableOldFwVersionText: {
    defaultMessage: 'SIPS and SRTP unavailable',
    description: 'Proxy info - sips and srtp unavailable',
    id: 'mobileVideoProxyInfo.securedCalls.unavailable.oldFwVersion.text',
  },
  mobileVideoProxyInfoSecuredCallsUnavailableOldFwVersionTooltip: {
    defaultMessage: 'Update firmware to make {br} SIPS and SRTP available',
    description: 'Proxy info - sips and srtp old fw version tooltip',
    id: 'mobileVideoProxyInfo.securedCalls.unavailable.oldFwVersion.tooltip',
  },
  mobileVideoProxyInfoSecuredCallsUnavailableUnsupportedDeviceText: {
    defaultMessage: 'SIPS and SRTP not supported',
    description: 'Proxy info - sips and srtp unsupported device',
    id: 'mobileVideoProxyInfo.securedCalls.unavailable.unsupportedDevice.text',
  },
  mobileVideoProxyInfoSecuredCallsUnavailableUnsupportedDeviceTooltip: {
    defaultMessage: 'Setting secured calls is not {br} supported for this device',
    description: 'Proxy info - sips and srtp unsupported device tooltip',
    id: 'mobileVideoProxyInfo.securedCalls.unavailable.unsupportedDevice.tooltip',
  },
  mobileVideoProxyInfoSipsPort: {
    defaultMessage: 'SIPS port',
    description: 'Proxy info - sips port',
    id: 'mobileVideoProxyInfo.sipsPort',
  },
  mobileVideoProxyInfoTitle: {
    defaultMessage: 'SIP Proxy',
    description: 'Proxy info - sip proxy title',
    id: 'mobileVideoProxyInfo.title',
  },
  mobileVideoRenewSettingsInfo: {
    defaultMessage: `Use this button to repair your intercom settings if needed.
      The parameters in the following tabs of section Services
      will be updated: SIP2, Calls, Audio and Video.
      My2N will also update Directory and Switches settings of your intercom.`,
    description: 'Service box - renew settings info',
    id: 'mobileVideoRenewSettings.info',
  },
  mobileVideoRenewSettingsTitle: {
    defaultMessage: 'Renew device service settings',
    description: 'Service box - renew settings button',
    id: 'mobileVideoRenewSettings.title',
  },
  mobileVideoServiceMobileVideo: {
    defaultMessage: '2N<sup>®</sup> Mobile Video',
    description: 'Activated services - mobile video',
    id: 'mobileVideoService.mobileVideo',
  },
  mobileVideoServiceModalActivate: {
    defaultMessage: 'Open your door safely and remotely with Mobile Video.',
    description: 'Activated services - text in dialog',
    id: 'mobileVideoService.modal.activate',
  },
  mobileVideoServiceModalActivateIPIntercom: {
    defaultMessage: 'Remember to assign this device to your intercom buttons.',
    description: 'Activated services - text in dialog',
    id: 'mobileVideoService.modal.activateIPIntercom',
  },
  mobileVideoServiceModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Cancel button',
    id: 'mobileVideoService.modal.cancelButton',
  },
  mobileVideoServiceModalConfirmButton: {
    defaultMessage: 'OK',
    description: 'Confirm button',
    id: 'mobileVideoService.modal.confirmButton',
  },
  mobileVideoServiceModalDeactivate: {
    defaultMessage: 'Mobile Video service won’t be available on this device.',
    description: 'Activated services - text in dialog',
    id: 'mobileVideoService.modal.deactivate',
  },
  mobileVideoServiceModalDeactivateIPIntercom: {
    defaultMessage: 'The device won’t be assigned to your intercom buttons.',
    description: 'Activated services - text in dialog',
    id: 'mobileVideoService.modal.deactivateIPIntercom',
  },
  mobileVideoServiceModalTitleDisable: {
    defaultMessage: 'Deactivate Mobile Video',
    description: 'Configuration service title modal - disable info',
    id: 'mobileVideoService.activationTitle.disable',
  },
  mobileVideoServiceModalTitleEnable: {
    defaultMessage: 'Activate Mobile Video',
    description: 'Configuration service title modal - enable info',
    id: 'mobileVideoService.activationTitle.enable',
  },
  mobileVideoSettingsAdaptiveVideoQualityHelperText: {
    defaultMessage: `Dynamically adjusts video quality depending on bandwidth available. Should
      you encounter any trouble, let us know <a>here</a> please.`,
    description: 'Mobile video settings - adaptive video quality helper text',
    id: 'mobileVideoSettings.adaptiveVideoQuality.helperText',
  },
  mobileVideoSettingsAdaptiveVideoQualityText: {
    defaultMessage: 'Adaptive Video Quality',
    description: 'Mobile video settings - adaptive video quality',
    id: 'mobileVideoSettings.adaptiveVideoQuality.text',
  },
  mobileVideoSettingsAdaptiveVideoQualityTooltipUpgradeAvailable: {
    defaultMessage: 'Upgrade firmware to make {br} Adaptive Video Quality available',
    description: 'Adaptive video quality tooltip upgrade available',
    id: 'mobileVideoSettings.adaptiveVideoQuality.tooltipUpgradeAvailable',
  },
  mobileVideoSettingsAdaptiveVideoQualityTooltipUpgradeNotAvailable: {
    defaultMessage: 'Adaptive Video Quality will be available {br} again from firmware version {version}',
    description: 'Adaptive video quality tooltip upgrade not available',
    id: 'mobileVideoSettings.adaptiveVideoQuality.tooltipUpgradeNotAvailable',
  },
  mobileVideoSettingsExternalCamera: {
    defaultMessage: 'External Camera in My2N mobile app',
    description: 'Mobile video settings - external camera checkbox',
    id: 'mobileVideoSettings.externalCamera',
  },
  mobileVideoSettingsFaceZooming: {
    defaultMessage: 'Face zooming',
    description: 'Mobile video settings - face zooming label',
    id: 'mobileVideoSettings.faceZooming',
  },
  mobileVideoSettingsFaceZoomingHelperText: {
    defaultMessage: 'The video zooms on the face of the person in front of the intercom camera.',
    description: 'Mobile video settings - face zooming callshelper text',
    id: 'mobileVideoSettings.faceZoomingHelperText',
  },
  mobileVideoSettingsRejectIncomingCalls: {
    defaultMessage: 'Reject incoming calls',
    description: 'Mobile video settings - Reject incoming calls',
    id: 'mobileVideoSettings.rejectIncomingCalls',
  },
  mobileVideoSettingsRejectIncomingCallsHelperText: {
    defaultMessage: `Users of My2N mobile app will not see the video stream
      while not in the call.`,
    description: 'Mobile video settings - reject incoming callshelper text',
    id: 'mobileVideoSettings.rejectIncomingCallsHelperText',
  },
  mobileVideoSettingsTitle: {
    defaultMessage: 'Mobile video settings',
    description: 'Mobile video settings - title',
    id: 'mobileVideoSettings.title',
  },
  mobileVideoStatusInfoLabel: {
    defaultMessage: 'Status info',
    description: 'Mobile video status info - label',
    id: 'mobileVideoStatusInfo.label',
  },
  mobileVideoStatusStatusActive: {
    defaultMessage: 'Active',
    description: 'Status active',
    id: 'mobileVideoStatus.status.active',
  },
  mobileVideoStatusStatusActiveUntil: {
    defaultMessage: '<strong>Active</strong> until {until}',
    description: 'Status active until',
    id: 'mobileVideoStatus.status.activeUntil',
  },
  mobileVideoStatusStatusNotActive: {
    defaultMessage: 'Inactive',
    description: 'Status not active',
    id: 'mobileVideoStatus.status.inactive',
  },
  mobileVideoStatusStatusTrialActive: {
    defaultMessage: '<strong>Active trial</strong> until {until}',
    description: 'Status active trial',
    id: 'mobileVideoStatus.status.trialActive',
  },
  mobileVideoStatusStatusTrialExpired: {
    defaultMessage: '<strong>Trial expired</strong>',
    description: 'Status trial expired',
    id: 'mobileVideoStatus.status.trialExpired',
  },
  renewMobileVideoDialogCancel: {
    defaultMessage: 'Cancel',
    description: 'Renew service dialog - cancel button',
    id: 'renewMobileVideoDialog.cancel',
  },
  renewMobileVideoDialogConfirm: {
    defaultMessage: 'Ok',
    description: 'Renew service dialog - confirm button',
    id: 'renewMobileVideoDialog.confirm',
  },
  renewMobileVideoDialogTitle: {
    defaultMessage: `Are you sure you want to renew part of the
      device service settings related to the Mobile Video Service?`,
    description: 'Renew service dialog - title',
    id: 'renewMobileVideoDialog.title',
  },
});
