import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyFinishRegistrationQuery } from '../../../../apiRtk/legacyFeApi';
import { PATHS } from '../../../../config/consts';
import { setFirstLogin, setPreferUserLogin } from '../../../../modules/auth/actions';
import { getCurrentUser } from '../../../../modules/user/actions';
import { getCurrentUserSelector } from '../../../../modules/user/selectors';
import { useOryLoginApi } from '../../../../ory/hooks/oryApi';
import { useOryResponseParser } from '../../../../ory/hooks/oryResponseParser';
import {
  defaultValues,
  //FormFieldNames,
  getActivateAccountValidationScheme,
  IActivateAccountFormData,
} from './validationScheme';

export interface IUseNewPasswordFormProps {
  onSuccess: () => void;
}

export interface IUseNewPasswordForm {
  formData: UseFormReturn<IActivateAccountFormData>;
  onFormSubmit: (values: IActivateAccountFormData) => void;
  isPasswordHelperTextVisible: boolean;
  email: string;
}

export function useActivateAccountForm({ onSuccess }: IUseNewPasswordFormProps): IUseNewPasswordForm {
  const [searchParams] = useSearchParams();
  const registrationId = searchParams.get('id');
  const email = searchParams.get('email');
  const preferUserLogin = searchParams.get('preferUserLogin') === 'true';
  const currentUser = useSelector(getCurrentUserSelector);
  const { formatMessage } = useIntl();
  const formData = useForm<IActivateAccountFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getActivateAccountValidationScheme(formatMessage)),
  });

  const {
    formState: { isSubmitSuccessful },
  } = formData;

  useEffect(() => {
    if (!registrationId || !email) {
      navigate(PATHS.LOGIN);
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && currentUser && currentUser.id) {
      navigate(PATHS.LOGIN);
    }
  }, [currentUser.id]);

  //TODO add effect to wait for loading current user and then redirect to login page to be redirected according to role

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [finishRegistration, { isFetching }] = useLazyFinishRegistrationQuery();
  const { loginToOry } = useOryLoginApi();
  const { parseOryResponse } = useOryResponseParser();

  console.log(isFetching);

  const { isDirty: isPasswordValidationDirty } = formData.getFieldState('password');
  const isPasswordHelperTextVisible = !isPasswordValidationDirty;
  //TODO check if you are already logged - in this case logout - not needed when add session refresh
  //TODO get queries
  //  id=96b04d75-bdc5-4bae-82ff-329087f0561b&operation=CONFIRM&email=testteam2n+tomas-user-35@gmail.com&preferUserLogin=true
  const onFormSubmit = async (values: IActivateAccountFormData) => {
    console.log(values);
    let registrationResponse;
    try {
      registrationResponse = await finishRegistration({
        body: { operation: 'CONFIRM', password: values.password },
        id: registrationId!,
      }).unwrap();

      if (registrationResponse?.email) {
        const response = await loginToOry({
          email: registrationResponse.email,
          password: values.password,
          sessionRefresh: true,
        });
        parseOryResponse({
          onSuccess: async () => {
            dispatch(getCurrentUser());
            dispatch(setPreferUserLogin({ preferUserLogin: preferUserLogin }));
            dispatch(setFirstLogin(true));
          },
          response,
        });
      }
    } catch (error) {
      console.log(error);

      // parseOryResponse({response: {}})
      // return
    }

    // if (email) {
    //TODO call /registration-mobile
    // } else {
    //TODO call /registration
    //}
    //TODO get email and login directly to ory
    //TODO set session confirmed

    //TODO go to login page

    onSuccess();
  };

  return {
    email: email!,
    formData,
    isPasswordHelperTextVisible,
    onFormSubmit: onFormSubmit,
  };
}
