import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CONFIGURATION_MODE } from '../../config/devices';
import Alert from '../Alert';
import messages from './messages';

const ConfigurationManualAlertBox = ({ mode }) =>
  mode === CONFIGURATION_MODE.MANUAL ? (
    <Alert severity="warning">
      <FormattedMessage {...messages.configurationModeTextManualAlert} />
    </Alert>
  ) : null;

ConfigurationManualAlertBox.propTypes = {
  mode: PropTypes.oneOf([CONFIGURATION_MODE.AUTOMATIC, CONFIGURATION_MODE.MANUAL]).isRequired,
};

export default ConfigurationManualAlertBox;
