import { defineMessages } from 'react-intl';

export default defineMessages({
  ImsActivateAccountConfirm: {
    defaultMessage: 'ACTIVATE ACCOUNT',
    id: 'ims.activateAccount.confirm',
  },
  ImsActivateAccountInfo: {
    defaultMessage: 'Activate your My2N account for <b>{userEmail}</b>',
    id: 'ims.activateAccount.info',
  },
  ImsActivateAccountTitle: {
    defaultMessage: 'Activate your account',
    id: 'ims.activateAccount.title',
  },
  ImsNewPasswordButtonSetPassword: {
    defaultMessage: 'Set password',
    id: 'ims.newPassword.button.setPassword',
  },
  ImsNewPasswordInputHelperText: {
    defaultMessage: 'Use at least: 8 characters, 1 uppercase letter, 1 lowercase letter, 1 digit',
    id: 'ims.newPassword.input.helperText',
  },
  ImsNewPasswordInputLabel: {
    defaultMessage: 'New password',
    id: 'ims.newPassword.input.label',
  },
});
