import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CONFIGURATION_MODE } from '../../config/devices';
import Alert from '../Alert';
import ConfigurationManualAlertBox from './ConfigurationManualAlertBox';
import messages from './messages';

const FAQ_URL = 'https://2nwiki.2n.cz/display/FAQ/How+to+correctly+use+autoprovisioning';

const ConfigurationModeText = ({ mode }) => (
  <>
    <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
      <ConfigurationManualAlertBox mode={mode} />
    </Box>
    <Alert severity="info">
      <FormattedMessage {...messages.configurationModeTextAutomatic} />
    </Alert>
    <Typography paragraph variant="body2">
      <FormattedMessage
        {...messages.configurationModeTextFaq}
        values={{
          url: (chunks) => (
            <Link href={FAQ_URL} rel="noopener noreferrer" target="_blank">
              {chunks}
            </Link>
          ),
        }}
      />
    </Typography>
  </>
);

ConfigurationModeText.propTypes = {
  mode: PropTypes.oneOf([CONFIGURATION_MODE.AUTOMATIC, CONFIGURATION_MODE.MANUAL]).isRequired,
};

export default ConfigurationModeText;
