import { defineMessages } from 'react-intl';

export default defineMessages({
  configurationModeTextAutomatic: {
    defaultMessage:
      'The automatic configuration ensures maximum security. Just make sure that your device is' +
      ' running the latest firmware. In the cloud connection mode, the device can reach all' +
      ' your devices within the cloud.',
    description: 'Automatic configuration mode text',
    id: 'configurationModeText.automatic',
  },
  configurationModeTextFaq: {
    defaultMessage: 'For more information, <url>see FAQs</url>.',
    description: 'Dialog faq text',
    id: 'configurationModeText.faq',
  },
  configurationModeTextManualAlert: {
    defaultMessage:
      'You are about to set your device to manual config. This mode will restrict your access' +
      ' to My2N cloud services. We cannot guarantee trouble-free operation of your' +
      ' devices.',
    description: 'Manual configuration alert info',
    id: 'configurationModeText.manualAlert',
  },
});
